import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import products from '../assets/images/products.jpg';
import NavPages from '../components/NavPages';
import SEO from '../components/SEO';

const ProductsStyled = styled.div`
  width: 100%;
  .products-header {
    background-image: linear-gradient(
        to bottom,
        var(--whiteTrans),
        var(--whiteTrans)
      ),
      url(${products});
  }
  .page-description,
  .series,
  .categories,
  .collections {
    margin: 2rem 0;
    h4 {
      text-align: center;
      padding: 2rem;
    }
    .wrap {
      /*display: grid;*/
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 2rem;
      align-items: center;
      justify-items; center;
      text-align: center;
      a {
        text-decoration: none;
        height: 100%;
      }
      > * {
        flex-grow: 1;
        flex-basis: calc(100% / 3 - 2rem);
      }
    }
    @media(max-width:1023px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }
  .wrap div {
    text-align: center;
    background: var(--backgroundCard);
    border: 1px solid var(--borderColor);
    background-position: center center;
    padding: 12rem 1rem;
    border-radius: 2rem;
    transition: var(--speed);
    box-shadow: var(--boxShadow);
    display: grid;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:hover {
      cursor: pointer;
    }
    h3 {
      color: var(--textColor);
      font-size: 2.75rem;
      @media(max-width: 1023px){
        font-size: 2.5rem;
      }
    }
    @media(max-width: 1023px){
      padding: 4rem 2rem;
    }
  }
`;

export default function ProductsPage({
  data: { products, categories, series },
}) {
  const categoriesLength = categories.nodes.length;
  const seriesLength = series.nodes.length;
  return (
    <>
      <SEO
        title="Explore Bont Rowing Products"
        description="All Bont Rowing shoes are made to the highest quality standards to ensure the best fit and performance, day in day out. Explore our products by category, series or collection."
      />
      <ProductsStyled>
        <div className="wrapper">
          <section className="page-header products-header">
            <div>
              <h2>Products</h2>
            </div>
          </section>
          <section className="page-description products-description">
            <p>
              All Bont Rowing shoes are made to the highest quality standards to
              ensure the best fit and performance, day in day out. Explore our
              products by category or series.
            </p>
          </section>
          <section className="categories">
            <h4>Category</h4>
            <div className="wrap" data-children-count={categoriesLength}>
              {categories.nodes.map((item) => (
                <Link to={`/category/${item.slug.current}`}>
                  <div
                    style={{
                      backgroundImage: `linear-gradient(
                        to bottom,
                        var(--whiteTrans),
                        var(--whiteTrans)
                      ),url(${
                        item.categoryImage?.asset.fluid.srcWebp
                          ? item.categoryImage.asset.fluid.srcWebp
                          : ''
                      })`,
                    }}
                  >
                    <h3>{item.title}</h3>
                  </div>
                </Link>
              ))}
            </div>
          </section>
          <section className="series">
            <h4>Series</h4>
            <div className="wrap" data-children-count={seriesLength}>
              {series.nodes.map((item) => (
                <Link to={`/series/${item.slug.current}`}>
                  <div
                    style={{
                      backgroundImage: `linear-gradient(
                        to bottom,
                        var(--whiteTrans),
                        var(--whiteTrans)
                      ),url(${
                        item.seriesImage?.asset.fluid.srcWebp
                          ? item.seriesImage.asset.fluid.srcWebp
                          : ''
                      })`,
                    }}
                  >
                    <h3>{item.title}</h3>
                  </div>
                </Link>
              ))}
            </div>
          </section>
        </div>
        <NavPages />
      </ProductsStyled>
    </>
  );
}

export const query = graphql`
  query {
    products: allSanityProducts {
      nodes {
        slug {
          current
        }
        title
        id
        description
        productImage {
          asset {
            fluid(maxWidth: 1000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    categories: allSanityCategories(
      filter: {
        slug: {
          current: { nin: ["discontinued", "spare-parts", "multi", "aero"] }
        }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
        categoryImage {
          asset {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
    series: allSanitySeries(
      filter: {
        slug: { current: { nin: ["discontinued", "spare-parts", "multi"] } }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        id
        slug {
          current
        }
        title
        seriesImage {
          asset {
            fluid {
              srcWebp
            }
          }
        }
      }
    }
  }
`;
